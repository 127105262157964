<script>

import Layout from "../../../layouts/main.vue";
import axios from "axios";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            appointment: [],
            sd: '',
            ed: '',
            status: [
                { id: 1, name: 'all' },
                { id: 2, name: 'pending' },
                { id: 3, name: 'reject' },
                { id: 4, name: 'accept' },
                { id: 5, name: 'new' }
            ],
            selectedItem: null,
            isClicked: false
        };
    },
    methods: {
        async getAppointment() {
            const response = await axios.get(
                "https://api.doctosoft.com/index.php/api/appointment/report/" + this.sd + '/' + this.ed + '/' + this.selectedItem
            );
            this.appointment = response.data;
        },
        onSubmit() {
            this.isClicked = true
        }
    },
    created() {
        this.getAppointment()

    }
};
</script>
<template>
    <Layout>
    <div class="mb-3">
            <label for="date" class="form-label">Start Date</label>
            <input type="date" v-model="sd" class="form-control" id="exampleInputdate" />
        </div>

        <div class="mb-3">
            <label for="date" class="form-label">End Date</label>
            <input type="date" v-model="ed" class="form-control" id="exampleInputdate" />
    </div>

        <div class="mb-3">
            <label for="status" class="form-label">Status</label>
            <select class="form-select" aria-label="size 3 select example" v-model="selectedItem">
                <option v-for="stat in status" :key="stat.id" :value="stat.name">
                    {{ stat.name }}
            </option>
        </select>
    </div>

    <div class="action-btn">
            <button class="btn  btn-md btn btn-primary" @click="getAppointment(); onSubmit();">submit</button>
        </div>

        <b-row v-if="isClicked == true">
            <b-col lg="12">
                <b-card no-body id="doctorTable">
                    <b-card-header class="d-flex align-items-center">
                    <h5 class="card-title flex-grow-1 mb-0">Appointment's Table</h5>
                </b-card-header>
                <b-card-body>
                    <div>
                        <div class="table-responsive table-card mb-3">
                                <table class="table align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <!-- <th scope="col" style="width: 50px;">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="checkAll"
                                                            value="option">
                                                    </div>
                                                </th> -->
                                        <th class="sort" data-sort="doctor" scope="col">Doctor</th>
                                        <th class="sort" data-sort="name" scope="col">Name</th>
                                        <th class="sort" data-sort="reason" scope="col">Reason</th>
                                        <th class="sort" data-sort="time" scope="col">Time</th>
                                        <th class="sort" data-sort="date" scope="col">Date</th>
                                        <th class="sort" data-sort="status" scope="col">Status</th>
                                        <!-- <th scope="col"
                                                    v-if="this.permission.appointment.update || this.permission.appointment.delete">
                                                    Action</th> -->
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                    <tr v-for="app in appointment" :key="app.id">
                                        <!-- <th scope="row">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" name="chk_child"
                                                            value="option1">
                                                    </div>
                                                </th> -->
                                        <td class="doctor">{{ app.doctor }}</td>
                                        <td class="name">{{ app.user }}</td>
                                        <td class="reason">{{ app.reason }}</td>
                                        <td class="time">{{ app.time }}</td>
                                        <td class="date">{{ app.date }}</td>
                                            <!-- <td class="comment">{{ app.doctorComments }}</td> -->
                                            <td class="status"><b-badge variant="badge-soft-success" :class="{
                                                        'badge-soft-success': app.status == 'accept',
                                                        'badge-soft-danger': app.status == 'reject',
                                                    'badge-soft-info': app.status == 'new',
                                                    'badge-soft-warning': app.status == 'pending',
                                                }">{{ app.status.charAt(0).toUpperCase() + app.status.slice(1) }}</b-badge></td>
                                        <!-- <div class="dropdown">
                                                    <b-button variant="soft-secondary" size="sm" class="dropdown"
                                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="ri-more-fill align-middle"></i>
                                                    </b-button>
                                                        <ul class="dropdown-menu dropdown-menu-end">
                                                            <li>
                                                            <b-link class="dropdown-item edit-item-btn" id="edit-item-btn"
                                                            @click="(updateData(app.id))"
                                                            v-if="this.permission.appointment.update">Edit</b-link>
                                                    </li>
                                                    <li>
                                                        <b-button class="dropdown-item remove-item-btn"
                                                            @click="confirm(app.id)"
                                                            v-if="this.permission.appointment.delete">Delete</b-button>
                                                    </li>
                                                        <li>
                                                            <b-button v-if="app.status != 'accept'" class="dropdown-item btn"
                                                            id="btn"
                                                            @click="changeStatus(app, 'accept')">Accept</b-button>
                                                    </li>
                                                        <li>
                                                            <b-button v-if="app.status != 'reject'" class="dropdown-item btn"
                                                                id="btn"
                                                                @click="changeStatus(app, 'reject')">Reject</b-button>
                                                        </li>
                                                        <li>
                                                            <b-button v-if="app.status != 'pending'" class="dropdown-item btn"
                                                                id="btn"
                                                                @click="changeStatus(app, 'pending')">Pending</b-button>
                                                        </li>
                                                    </ul>
                                                    </div> -->
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <div class="noresult" style="display: none">
                            <div class="text-center">
                              <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                                :height="75" :width="75" />
                              <h5 class="mt-2">Sorry! No Result Found</h5>
                              <p class="text-muted mb-0">We've searched more than 150+ API Keys We did not find any API for you
                                search.</p>
                            </div>
                          </div> -->
                            </div>
                            <!-- <div class="d-flex justify-content-end mt-3">
                          <div class="pagination-wrap hstack gap-2">
                            <b-link class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                              Previous
                            </b-link>
                            <ul class="pagination listjs-pagination mb-0">
                              <li :class="{ active: pageNumber == page, disabled: pageNumber == '...', }"
                                v-for="(pageNumber, index) in pages" :key="index" @click="page = pageNumber">
                                <b-link class="page" href="#">{{ pageNumber }}</b-link>
                              </li>
                            </ul>
                            <b-link class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                              Next
                            </b-link>
                      </div> -->
                            <!-- </div> -->
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

    </Layout>
</template>
